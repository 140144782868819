export enum Features {
  UNDER_MAINTENANCE = "under-maintenance",
  DOWNLOAD_CSV_CLM = "download-csv-clm",
  MRP_PLANNER_ROLE = "mrp-planner-role",
  NPD_BUYER_ROLE = "npd-buyer-role",
  CONTRACT_CONSUMPTION_COLUMN = "contract-consumption-column",
  PSL_PLANTS = "psl-plants",
  MRP_PRICE_GRID = "mrp-price-grid",
  PR_COMMENTS_RESTRICTION = "pr-comments-restriction",
  NEW_COMPANY_CODE = "new-company-code",
  PA_ACTION_BUTTONS = "pa-action-buttons",
  EXPORT_RC_DOCS = "export-rc-docs",
  PRISM_BUTTON = "prism-button",
  PRISM_SYNC_EVENTS = "prism-sync-events",
  MRP_SIGNED_AGREEMENT_NO_VALIDATION = "mrp-signed-agreement-no-validation",
  RC_APPROVAL_DATES = "rc-approval-dates",
  BUYER_NAME_IN_MRP_FILTERS = "buyer-name-in-mrp-filters",
  PR_APPROVAL_STATUS_CHANGE_AFS = 'pr-approval-status-change-afs',
  REMOVE_APPROVAL_LIST_DISPLAY_LIMIT = "remove-approval-list-display-limit",
  MRP_SEARCH_BAR = "mrp-search-bar",
  MRP_PART_ID_DESCP = "mrp-part-id-description",
  PSL_URL_FIELD = "psl-url-field",
  NEW_PRICE_LIST_COMPONENT_PSL = "new-price-list-component-psl",
  PO_APPROVAL_COMP_IND = "po-approval-comp-ind",
  PSL_WAYS_OF_BUYING = "psl-ways-of-buying",
  HIDE_PLANT_IN_PSL_FILTERS = "hide-plant-in-psl-filters",
  SPEND_CATEGORY_IN_PSL_FILTERS = "spend-category-in-filters",
  SHOW_LOCATION_FIELD_IN_PSL_FILTERS = "show-location-field-in-psl-filters",
  USER_AT_MARKET_NO_EDIT_PARENT_PSL = "user-at-market-no-edit-parent-psl",
  MRP_ENTRIES_WITH_ERRONEOUS = "mrp-entries-with-erroneous",
  NEW_BA_PA_SCREENS = "new-pa-ba-screens",
  BA_ACC_DATA_DESCRIPTION = "ba-acc-data-description",
  HIDE_FILE_UPLOAD = "hide-file-upload",
  SUBSTITUTION_MASS_DELETION = "substitution-mass-deletion",
  OPEN_LOCALIZATION_FROM_EMAIL = 'open-localization-from-email',
  MRP_SPEND_CATEGORY = "mrp-spend-category",
  NEW_MRP_BUTTONS = "new-mrp-buttons",
  REFACTOR_S2P = "refactor-s2p",
  MRP_LANGUAGE_FIELD = "mrp-language-field",
  FEEDBACK_STATUS_COLUMN = "feedback-status-column", // this is to render new version of admin feedback
  SUBSTITUTION_ZONE_TAB = "substitution-zone-tab",
  MRP_HISTORY_SCREEN = "mrp-history-screen",
  NEW_PA_DETAILS_PAGE = "new-pa-details-page",
  DOCFLOW_APPROVAL = 'docflow-approval',
  AFS_ATTACHMENT = "afs-attachment",
  MRP_ADDITIONAL_CONTACTS = "mrp-additional-contacts",
  SHOW_NBS_FOR_OP_BUYER_ONLY = "show-nbs-for-op-buyer-only",
  NBS_COCKPIT = "nbs-cockpit",
  PSL_OBSOLETE_ENTRIES = "deletion-reactivation-parent-and-children",
  FEEDBACK_COCKPIT_SHARE_FEEDBACK = "feedback-cockpit-share-feedback", // this is to render feedback cockpit
  PSL_MAINTENANCE_TAB_PAGINATION = "psl-maintenance-tab-pagination",
  PSL_MAINTENANCE_TAB_PAGINATION_APPROVERS = "psl-maintenance-tab-pagination-approvers",
  PSL_MAINTENANCE_TAB_PAGINATION_LOCALIZERS = "psl-maintenance-tab-pagination-localizers",
  PSL_FAVORITE = "psl-favorite",
  RC_CHAT_NOTES_FILTER = "rc-chat-notes-filter",
  PSL_SINGLE_API_FOR_VENDOR_WITH_METERIALS = "psl-single-api-for-vendor-and-materials",
  PSL_REACTIVATION_WITH_ALL_FIELDS = "psl-reactivation-and-updating-all-fields",
  SEARCH_ALL_PSL = "search-all",
  MY_TASK_STATIC_FILTER = "my-task-static-filter",
  PSL_162445_ADD_PLANT_FILTER_AND_SEARCH = "psl-162445-add-plant-filter-and-search",
  PSL_124675_Plants = "psl-124675-plants",
  RC_163025_RESUBMIT_BUTTON = "rc-163025-resubmit-button",
  psl_162779_edit_pls_second_page = "psl-162779-edit-pls-second-page",
  PSL_163152_SEARCHALL_HIGHLIGHT = "psl-163152-searchall-highlight",
  psl_162778_search_state_mgmt = "psl-162778-search-state-mgmt",
  RC_163242_NEW_ACTIVE_FILTER_DESIGN = "rc-163242-new-active-filter-design",
  FB_163177_ADMIN_CHAT = "feedback-163177-admin-chat",
  RC_163396_Hover_Contact_Card = "rc-163396-hover-contact-card",
  PSL_163225_COLUMN_STATUS = "psl-163225-column-status",
  RC_163485_AVATAR_USER_PICTURE = "rc-163485-avatar-user-picture",
  RC_163611_RESEND_PO = "rc-163611-resend-po",
  RC_163395_ACCOUNT_TAB = "rc-163395-account-tab",
  RC_163612_NOTES_FILTER = "rc-163612-notes-filter",
  MRP_163739_PRICE_LIST_LINK = 'mrp-163739-price-list-link',
  RC_163642_INVOICE_CHAT_FILTERS = "rc-163642-invoice-chat-filters",
  PSL_163766_CACHE_SEARCH_OPTIONS = "psl-163766-cache-search-options",
  RC_163722_PARKING_REASON_CODE_FILTER = "rc-163722-parking-reason-code-filter",
  RC_163946_ADD_NEW_ITEM_BUTTON = "rc-163946-add-new-item-button",
  RC_164144_ACCOUNT_TAB_DATA_SHOWING_LOGIC = "rc-164144-account-tab-data-showing-logic",
  PSL_163770_VIEW_CHILD_PSL_FROM_PARENT = "psl-163770-view-child-psl-from-parent",
  RC_164177_SHOW_RESEND_PO_PDF_BUTTON_ON_CONDITIONS = 'rc-164177-show-resend-po-pdf-button-on-conditions',
  RC_163868_NEW_ACCOUNT_TAB = "rc-163868-new-account-tab",
  RC_164276_REMOVE_CHANGE_REQUEST = "rc-164276-remove-change-request",
  RC_163721_BLOCKING_REASON_CODE = "rc-163721-blocking-reason-code",
  RC_164273_Mapvenflag_Nopohidresndbtn_Popdatmainlin = "rc-164273-mapvenflag-nopohidresndbtn-popdatmainlin",
  PSL_163229_SUPPLIER_FILTER_ENHANCEMENT = "psl-163229-supplier-filter-enhancement",
  RC_164613_EDIT_SUPPLIER_COMMENTS_DOCTYPE_FO = "rc-164613-edit-supplier-comments-doctype-fo",
  RC_164600_SHOW_HIDE_PRICE = "rc-164600-show-hide-price",
  PSL_164486_HIGHLIGHT_BUG_FIX = "psl-164486-highlight-bug-fix",
  PSL_163793_LOCALIZE_UNITPRICE = "psl-163793-localize-unitprice",
  PSL_164503_AUTO_RESTORE_COLUMN = "psl-164503-auto-restore-column",
  RC_165259_Doc_Filters_With_Paste_Button = "rc-165259-doc-filters-with-paste-button",
  NBS_165416_BLOCKED_PARKED_TAB = "nbs-165416-blocked-parked-tab",
  RC_165281_NEW_FIELD_AND_SUPLIER_MODIFIED = "rc-165281-new-field-and-suplier-modified",
  RC_166262_EDIT_PR_FOR_SPECIFIC_USERS = "rc-166262-edit-pr-for-specific-users",
  NBS_166278_HISTORY_LOG_TAB = "nbs-166278-history-log-tab",
  PSL_166244_FEATURE_VENDORINFO = "psl-166244-feature-vendorinfo",
  NBS_165964_MAINTEANCE_PAGE = "nbs-165964-mainteance-page",
  RC_165911_SPENDOR_UPDATE_WITH_PO_SENDING = "rc-165911-spendor-update-with-po-sending",
  PSL_166590_PSL_SCREEN_POPUP_ICON_SHOWINGS = "psl-166590-psl-screen-popup-icon-showings",
  MRP_166357_PLANNED_DELIVERY_TIME = "mrp-166357-planned-delivery-time",
  RC_166717_DISABLE_EDIT_PR_PAGE_INPUTS = "rc-166717-disable-edit-pr-page-inputs",
  PSL_166920_SHOW_REF_NUMBER = "psl-166920-show-ref-number",
  MRP_167239_NEW_CATALOG_TYPE = "mrp-167239-new-catalog-type",
  RC_166756_DISPLAY_AND_SEARCH_PROJECT_TITLE = "rc-166756-display-and-search-project-title",
  RC_167322_NEW_PR_APPROVER_HOVER = "rc-167322-new-pr-approver-hover",
  PSL_166241_NEW_SPENDER_VIEW = "psl-166241-new-spender-view",
  RC_166203_NEW_GENERAL_DETAILS_FIELDS = "rc-166203-new-general-details-fields",
  RC_166206_INCOTERMS = "rc-166206-incoterms",
  NBS_167094_SUPPLIER_TASKRES_FILTER = "nbs-167094-supplier-taskres-filter",
  NBS_TEMPLATE_167402_SHOW_COLUMNS_UI = "nbs-template-167402-show-columns-ui",
  NBS_TEMPALATE_167856_SHOW_EXPORT = "nbs-tempalate-167856-show-export",
  PSL_167588_HIDE_MORE_OPTIONS_FOR_REFNUMBER = "psl-167588-hide-more-options-for-refnumber",
  RC_167830_SUPPLIER_FILTER = "rc-167830-supplier-filter",
  RC_166302_SHOW_US_TAX_CALCULATIONS = "rc-166302-show-us-tax-calculations",
  NBS_167763_MAINTENANCE_IMPORT = "nbs-167763-maintenance-import",
  NBS_167096_STATUS_LINE = "nbs-167096-status-line",
  RC_167352_NEW_5_FILTERS = "rc-167352-new-5-filters",
  PSL_167882_SHOW_API_DATA_FOR_SCOPE_FILTER = "psl-167882-show-api-data-for-scope-filter",
  MRP_166357_PLANNED_DELIVERY_TIME_2 = "mrp-166357-planned-delivery-time-2",
  NBS_167945_BLOCK_PARKED_SHOW_FILTERS_COPY_PASTE ="nbs-167945-block-parked-show-filters-copy-paste",
  NBS_167630_NEW_CHAT_CONTACTS = "nbs-167630-new-chat-contacts",
  RC_168166_NEW_COLUMNS_ITEM_LEVEL = "rc-168166-new-columns-item-level",
  NBS_168175_REMOVE_Z200_Z199_FROM_API_RESPONSE = "nbs-168175-remove-z200-z199-from-api-response",
  PSL_168065_SHOW_MATERIAL_ID = "psl-168065-show-material-id",
  NBS_168170_SAMPLE_IMPORT_TEMPLATE = "nbs-168170-sample-import-template",
  RC_DISABLING_GENERAL_DETAIL_FIELDS = "rc-disabling-general-detail-fields",
  RC_167254_NEW_FILTERS = "rc-167254-new-filters",
  PSL_168068_MATERIAL_ID_FILTER = "psl-168068-material-id-filter",
  NBS_168421_SHOW_SAP_SCOPE_TAB = "nbs-168421-show-sap-scope-tab",
  NBS_168589_TOGGLE_BTN_COLUMN = "nbs-168589-toggle-btn-column",
  RC_168614_PRICING_CONDITIONS = "rc-168614-pricing-conditions",
  FAIR_MARKIT_FILTER = "fair-markit-filter",
  NBS_169184_SHOW_WORKFLOW_AGENT_VIEW_ICON = "nbs-169184-show-workflow-agent-view-icon",
  PSL_169580_SPENDER_VIEW_TOGGLE_BTN = "psl-169580-spender-view-toggle-btn",
  NBS_169183_SAP_SCOPE_SHOW_ATTACHMENT_ICON = "nbs-169183-sap-scope-show-attachment-icon",
  RC_169651_PLANT_EDIT_SHOW = "rc-169651-plant-edit-show",
  RC_169620_SHOW_INCOTERMS_EDIT_DESCRIPTION ="rc-169620-show-incoterms-edit-description",
  RC_169649_ENABLE_EMAIL_ACC_TAB_AND_PLANT_VENDOR = "rc-169649-enable-email-acc-tab-and-plant-vendor",
  RC_172318_STOP_EDIT_EASY_REQ_KIND_PR_MAILS = "rc-172318-stop-edit-easy-req-kind-pr-mails",
  MRP_FAIRMRKT_INITIATE_RFQ_168423 = "mrp-fairmrkt-initiate-rfq-168423",
  MRP_FAIRMRKT_INITIATE_RFQ_168423_ITEM_LEVEL = "mrp-fairmrkt-initiate-rfq-168423-item-level",
  PSL_URL_CHANGE_POWERAPPS = "psl-url-change-powerapps",
  NBS_172427_BP_EXPORT_EXCEL ="nbs-172427-bp-export-excel",
  NBS_172418_BP_NOTES_TAB = "nbs-172418-bp-notes-tab",
  RC_172495_SEND_PO_COMMENTS_TO_SAP ="rc-172495-send-po-comments-to-sap",
  RC_168931_FAIRMARKT_NEW_FILTERS_COLUMNS = "rc-168931-fairmarkt-new-filters-columns"
}

export enum RemoteConfigurableValues {
  USER_ROLES = "user-roles",
  SKIP_EMAIL_FOR_EASY_REQ = "skip-email-for-easy-req",
  SKIP_PR_NO_ITEM_FIELD = "skip-pr-no-item-field",
  PO_PDF_CUTOFF_DATE = "po-pdf-cutoff-date"
}

export function getPataFlagsApiKey(): string {
  switch (process.env.REACT_APP_ENV.trim()) {
    case "test":
      return "978d2a9b-56d2-4715-9f06-f0c6c702f1aa";
    case "rte":
      return "dc2df5fd-918a-4334-acbf-6a75220cc771";
    case "prod":
      return "ce6432b4-3cf0-426a-842b-ff4c1a801a15";
    case "dev":
    default:
      return "23809bae-a1ec-409a-9ebc-1aed6ca4e6fd";
  }
}

export function getPataFlagsDefaultValues() {
  switch (process.env.REACT_APP_ENV.trim()) {
    case "prod":
      return {
      };
    case "test":
      return {
        [Features.DOWNLOAD_CSV_CLM]: true,
        [Features.PR_COMMENTS_RESTRICTION]: true,
      };
    case "rte":
    case "dev":
    default:
      return {
        [Features.DOWNLOAD_CSV_CLM]: true,
        [Features.PSL_PLANTS]: true,
        [Features.PR_COMMENTS_RESTRICTION]: true,
      };
  }
}